import { BULLET_GRAPH_COLORS, VUETIFY_COLORS as DEFAULT_VUETIFY_COLORS, TIMESERIES_COLORS } from '../default/colors.js'
import { VuetifyColors } from '../types.js'

const VUETIFY_COLORS = {
  ...DEFAULT_VUETIFY_COLORS,
  accent: {
    base: '#527870'
  },
  primary: {
    base: '#85a88c',
    darken2: '#527870',
    darken4: '#364f52',
    lighten2: '#c9d1c4',
    lighten3: '#dfe3dc',
    lighten4: '#eef4eb'
  }
} as VuetifyColors

const COCKPIT_NUDGE_GRADIENT = [
  '#2e3d45',
  '#527870',
  '#85a88c'
] as string[]

export { BULLET_GRAPH_COLORS, COCKPIT_NUDGE_GRADIENT, TIMESERIES_COLORS, VUETIFY_COLORS }
