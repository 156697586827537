import { Texts } from '../types.js'
import TEXTS from '../default/texts.js'

export default {
  ...TEXTS,
  appName: 'Climatch Optimiser',
  companyName: 'Climatch GmbH',
  emailSupport: 'optimiser@swisslife-am.com',
  phoneSupport: null,
} as Texts
